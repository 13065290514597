<template>
	<div class="container">
		<!-- Main Layer -->
		<div class="ewm page-sub-box">
			<div class="flex flex-col gap-y-6 divide-y">
				<!-- Top Layer -->
				<div>
					<table class="min-w-max max-w-screen-xl mt-6 mb-4 h-28">
						<colgroup>
							<col style="width: 90px" />
							<col style="width: auto" />
							<col style="width: 80px" />
							<col style="width: auto" />
							<col style="width: 100px" />
							<col style="width: auto" />
						</colgroup>

						<tbody>
							<tr>
								<th scope="row">
									<label for="label01">시험계획명: </label>
								</th>
								<td>
									<DxTextBox
										v-model="examScheduleInfo.scheNm"
										:styling-mode="config.stylingMode"
										:width="200"
										:height="30"
										:max-length="limitNumberTexts.maxLengths.scheNm"
										@key-up="$_checkLimitTextLength($event, examScheduleInfo, limitNumberTexts, 'scheNm')"
									>
										<DxValidator>
											<DxRequiredRule message="시험계획명은 필수입니다." />
										</DxValidator>
									</DxTextBox>
								</td>
								<th scope="row">
									<label for="label01">시험지명:</label>
								</th>
								<td>
									<div class="flex space-x-2">
										<DxTextBox
											v-model="sheetData.examTypeNm"
											:styling-mode="config.textStylingMode"
											:width="100"
											:height="30"
											:read-only="true"
										>
											<DxValidator>
												<DxRequiredRule message="시험지는 필수입니다." />
											</DxValidator>
										</DxTextBox>
										<DxTextBox
											v-model="sheetData.examNm"
											:styling-mode="config.textStylingMode"
											:width="120"
											:height="30"
											:read-only="true"
										>
											<DxValidator>
												<DxRequiredRule message="시험지는 필수입니다." />
											</DxValidator>
										</DxTextBox>
										<DxButton
											icon="search"
											:width="40"
											:height="30"
											:disabled="!config.canModify"
											@click="chooseExamSheet"
										/>
										<DxButton
											text="미리보기"
											class="btn_XS default filled"
											:width="75"
											:height="30"
											@click="previewExamSheet"
										/>
									</div>
								</td>
								<th scope="row">
									<label for="label01">임의문항순서:</label>
								</th>
								<td>
									<DxCheckBox
										v-model="examScheduleInfo.examQuestionRandCd"
										:value="examScheduleInfo.examQuestionRandCd"
									/>
								</td>
							</tr>
							<tr>
								<th scope="row">
									<label for="label01">시험일:</label>
								</th>
								<td>
									<div>
										<DxDateBox
											v-model="examScheduleInfo.examStartDt"
											:styling-mode="config.stylingMode"
											:width="120"
											type="date"
											dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
											display-format="yyyy.MM.dd"
										>
											<DxValidator>
												<DxRequiredRule message="시험일은 필수입니다." />
											</DxValidator>
										</DxDateBox>
									</div>
								</td>
								<th scope="row">
									<label for="label01">시험시간:</label>
								</th>
								<td>
									<div class="flex space-x-2">
										<div class="ui-datepicker-item">
											<DxDateBox
												v-model="examScheduleInfo.examStartTime"
												:styling-mode="config.stylingMode"
												displayFormat="HH:mm"
												:width="110"
												type="time"
												invalid-date-message="입력 데이터가 유효하지 않습니다."
												:max="examScheduleInfo.examEndTime"
											>
												<DxValidator>
													<DxRequiredRule message="시험시간은 필수입니다." />
												</DxValidator>
											</DxDateBox>
										</div>
										<div class="ui-datepicker period">
											<span class="dash">~</span>
										</div>
										<div class="ui-datepicker-item">
											<DxDateBox
												v-model="examScheduleInfo.examEndTime"
												:styling-mode="config.stylingMode"
												displayFormat="HH:mm"
												:width="110"
												type="time"
												invalid-date-message="입력 데이터가 유효하지 않습니다."
												:min="examScheduleInfo.examStartTime"
											>
												<DxValidator>
													<DxRequiredRule message="시험시간은 필수입니다." />
												</DxValidator>
											</DxDateBox>
										</div>
									</div>
								</td>
								<th scope="row">
									<label for="label01">이의제기 기간:</label>
								</th>
								<td>
									<div class="flex">
										<div>
											<DxDateBox
												v-model="examScheduleInfo.examFeedbackStartDt"
												:styling-mode="config.stylingMode"
												:width="120"
												type="date"
												dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
												display-format="yyyy.MM.dd"
												:max="examScheduleInfo.examFeedbackEndDt"
												maxLength="10"
												invalid-date-message="입력 데이터가 유효하지 않습니다."
											>
												<DxValidator>
													<DxRequiredRule message="이의제기 기간은 필수입니다." />
												</DxValidator>
											</DxDateBox>
										</div>
										<div class="ui-datepicker period">
											<span class="dash">~</span>
										</div>
										<div>
											<DxDateBox
												v-model="examScheduleInfo.examFeedbackEndDt"
												:styling-mode="config.stylingMode"
												:width="120"
												type="date"
												dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
												display-format="yyyy.MM.dd"
												:min="examScheduleInfo.examFeedbackStartDt"
												maxLength="10"
												invalid-date-message="입력 데이터가 유효하지 않습니다."
											>
												<DxValidator>
													<DxRequiredRule message="이의제기 기간은 필수입니다." />
												</DxValidator>
											</DxDateBox>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!-- Top Layer -->

				<!-- 2차 -->
				<div class="flex gap-x-6">
					<div class="w-2/5">
						<h3 class="text-xl font-medium my-4">채점자</h3>
						<div>
							<div class="flex justify-start">
								<DxButton
									text="채점자추가"
									type="button"
									class="btn_XS default filled add1"
									:height="30"
									:disabled="!config.canModify"
									@click="onOpen('MarkerAdd')"
								/>
								<DxButton
									text="삭제"
									type="button"
									class="btn_XS white light_filled trash"
									:height="30"
									:disabled="!config.canModify"
									@click="onManagerDeleteData"
								/>
							</div>
							<div class="mt-4">
								<DxDataGrid
									class="grid-box"
									ref="managerGridData"
									:data-source="managerGridData.dataSource"
									:allow-column-resizing="true"
									:column-resizing-mode="'nextColumn'"
									:show-borders="false"
									:show-column-headers="true"
									:show-column-lines="true"
									:show-row-lines="true"
									:row-alternation-enabled="false"
									:hover-state-enabled="true"
									:word-wrap-enabled="true"
									:no-data-text="this.$_msgContents('CMN_NO_DATA')"
									:selected-row-keys="managerGridData.selectedRowKeys"
									@row-click="onRowClick"
									@cell-hover-changed="onCellHoverChanged"
									width="100%"
									height="100%"
								>
									<DxLoadPanel :enabled="true" />
									<DxScrolling mode="standard" />
									<DxFilterRow :visible="true" />
									<DxSelection
										mode="multiple"
										:allow-select-all="true"
										show-check-boxes-mode="always"
										select-all-mode="allPages"
									/>

									<DxColumn
										caption="부서정보"
										data-field="deptNmPath"
										:allowEditing="false"
										:allow-sorting="false"
										alignment="center"
									/>
									<DxColumn
										caption="채점자[ID]"
										width="120"
										data-field="agtid"
										:allowEditing="false"
										:allow-sorting="false"
										alignment="center"
										:calculate-display-value="rowData => `${rowData.agtNm}[${rowData.agtid}]`"
									/>
									<DxColumn
										caption="배정 대상자"
										width="100"
										data-field="targetCnt"
										:allowFiltering="false"
										:allowEditing="false"
										:allow-sorting="false"
										alignment="center"
									/>
								</DxDataGrid>
							</div>
						</div>
					</div>
					<div class="w-3/5">
						<h3 class="text-xl font-medium my-4">시험대상자</h3>
						<div>
							<div class="flex justify-start">
								<DxButton
									text="대상자추가"
									type="button"
									class="btn_XS default filled add1"
									:height="30"
									:disabled="!config.canModify"
									@click="onOpen('TargetAdd')"
								/>
								<DxButton
									text="채점자자동배정"
									type="button"
									class="btn_XS default filled"
									:height="30"
									:disabled="!config.canModify"
									@click="onAssignManager"
								/>
								<DxButton
									text="삭제"
									type="button"
									class="btn_XS white light_filled trash"
									:height="30"
									:disabled="!config.canModify"
									@click="onTargetDeleteData"
								/>
							</div>
							<div class="mt-4">
								<DxDataGrid
									class="grid-box"
									ref="targetGridData"
									:data-source="targetGridData.dataSource"
									:allow-column-resizing="true"
									:column-resizing-mode="'nextColumn'"
									:show-borders="false"
									:show-column-headers="true"
									:show-column-lines="true"
									:show-row-lines="true"
									:row-alternation-enabled="false"
									:hover-state-enabled="true"
									:word-wrap-enabled="true"
									:no-data-text="this.$_msgContents('CMN_NO_DATA')"
									:selected-row-keys="targetGridData.selectedRowKeys"
									@row-click="onRowClick"
									@cell-hover-changed="onCellHoverChanged"
									width="100%"
									height="100%"
								>
									<DxLoadPanel :enabled="true" />
									<DxScrolling mode="standard" />
									<DxFilterRow :visible="true" />
									<DxSelection
										mode="multiple"
										:allow-select-all="true"
										show-check-boxes-mode="always"
										select-all-mode="allPages"
									/>

									<DxColumn
										caption="부서정보"
										data-field="deptNmPath"
										:allowEditing="false"
										:allow-sorting="false"
										alignment="center"
									/>
									<DxColumn
										caption="업무그룹"
										data-field="workgroupNm"
										:allowEditing="false"
										:allow-sorting="false"
										:allowFiltering="false"
										width="120"
										alignment="center"
									>
										<!-- <DxLookup
											:data-source="codes.workgroupCd.dataSource"
											value-expr="codeId"
											display-expr="codeNm"
										/> -->
									</DxColumn>
									<DxColumn
										caption="대상자[ID]"
										data-field="agtid"
										:allowEditing="false"
										:allow-sorting="false"
										alignment="center"
										width="120"
										:calculate-display-value="rowData => `${rowData.agtNm}[${rowData.agtid}]`"
									/>
									<DxColumn
										caption="채점자[ID]"
										data-field="managerNm"
										cell-template="managerTemplate"
										:allowEditing="false"
										:allow-sorting="false"
										:allowFiltering="false"
										alignment="center"
										width="210"
									/>

									<template #managerTemplate="{ data }">
										<div class="flex flex-nowrap justify-end items-center gap-x-4">
											<div v-if="data.data.managerId">
												{{ `${data.data.managerNm}[${data.data.managerId}]` }}
											</div>
											<DxButton
												text="편집"
												class="btn_XS white light_filled"
												:disabled="!config.canModify"
												@click="onEditTargetManager($event, data)"
											/>
										</div>
									</template>
								</DxDataGrid>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

    <!-- Foot Button -->
    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <h2 class="hidden">일반 버튼</h2>
        <div class="bottom-btn-wrap">
          <DxButton
              text="저 장"
              class="btn_XS default filled txt_S medium"
              :disabled="!config.canModify"
              :width="120"
              :height="40"
              :use-submit-behavior="true"
              @click="onSaveFormData"
          />
          <DxButton
              text="닫 기"
              class="btn_XS white filled txt_S medium"
              :width="120"
              :height="40"
              @click="onCancelFormData"
          />
        </div>
      </div>
    </section>
    <!-- Foot Button -->

		<!-- Agent Modal -->
		<modal-add-agent
			:isOpen="modal.agent.popupVisible"
			:showModalTitle="true"
			:selectedIdList="modal.agent.selectedIdList"
			@closeModal="onClose(false)"
			@saveModal="onSave"
		/>
		<!-- Agent Modal -->

		<!-- Modal Layer -->
		<DxPopup
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:min-width="modal.initData ? modal.initData.width : null"
			:width="modal.initData ? modal.initData.width : null"
			:min-height="modal.initData ? modal.initData.height : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			v-model="modal.isOpened"
			:visible="modal.isOpened"
			@hiding="isOpenModal(false)"
		>
			<template #content>
				<div>
					<component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
				</div>
			</template>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.hasOwnProperty('save')
							: !modal.initData.buttons.hasOwnProperty('save')
						: false
				"
				:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						onConfirmModal();
					},
				}"
			/>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
				:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						isOpenModal(false);
					},
				}"
			/>
		</DxPopup>
		<!-- /Modal Layer -->
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import {
	DxDataGrid,
	DxColumn,
	DxEditing,
	DxLoadPanel,
	DxLookup,
	DxScrolling,
	DxSelection,
	DxSorting,
	DxFilterRow,
} from 'devextreme-vue/data-grid';
import { DxValidator, DxCustomRule, DxPatternRule, DxRangeRule, DxRequiredRule } from 'devextreme-vue/validator';
import { isSuccess } from '@/plugins/common-lib';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import ModalChooseSheet from '@/pages/ewm/exam/implementation/exam-schedule/modal-choose-sheet.vue'; //시험지 선택
import ModalMarkerEdit from '@/pages/ewm/exam/implementation/exam-schedule/modal-marker-edit.vue'; //채점자 편집

import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';

let vm = null;

export default {
	components: {
		DxButton,
		DxTextBox,
		DxDateBox,
		DxCheckBox,

		DxColumn,
		DxDataGrid,
		DxEditing,
		DxLoadPanel,
		DxLookup,
		DxScrolling,
		DxSelection,
		DxSorting,
		DxFilterRow,

		DxValidator,
		DxCustomRule,
		DxPatternRule,
		DxRangeRule,
		DxRequiredRule,

		DxPopup,
		DxToolbarItem,
		ModalChooseSheet,
		ModalMarkerEdit,

		ModalAddAgent,
	},
	props: {},
	watch: {},
	data() {
		return {
			codes: {
				// workgroupCd: {
				// 	dataSource: [],
				// 	displayExpr: 'codeNm',
				// 	valueExpr: 'codeId'
				// },
				examSheetType: {
					dataSource: [],
					displayExpr: 'label',
					valueExpr: 'value',
				},
			},
			config: {
				stylingMode: 'outlined', //outlined, underlined, filled
				textStylingMode: 'filled',
				updateYn: false,
				canModify: true,
			},
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				contentData: null,
				componentName: '',
				agent: {
					locName: '',
					popupVisible: false,
					selectedIdList: [],
					managerSelectedIdList: [],
					targetSelectedIdList: [],
				},
			},
			limitNumberTexts: {
				textLengths: {},
				maxLengths: {
					scheNm: 100,
				},
			},
			examScheduleInfo: {
				examQuestionRandCd: false,
			},
			managerGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			targetGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			sheetData: {},
			isSelectionStopped: true,
		};
	},
	computed: {
		ewmCodes() {
			return this.$_enums.ewm;
		},
	},
	methods: {
		/** @description : 상담사 셀 호버 이벤트  */
		onCellHoverChanged(e) {
			const event = e.event;
			if (event.buttons === 1) {
				if (this.isSelectionStopped) {
					this.isSelectionStopped = false;
					this.selectedRange = {};
				}

				if (this.selectedRange.startRowIndex === undefined) {
					this.selectedRange.startRowIndex = e.rowIndex;
				}

				if (!this.selectedRange.startColumnIndex) {
					this.selectedRange.startColumnIndex = e.columnIndex;
				}

				this.selectedRange.endRowIndex = e.rowIndex;
				this.selectedRange.endColumnIndex = e.columnIndex;

				let start = Math.min(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);
				let end = Math.max(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);

				let indexes = [];
				for (let i = start; i <= end; i++) {
					indexes.push(i);
				}
				e.component.selectRowsByIndexes(indexes);
			} else {
				this.isSelectionStopped = true;
			}
		},
		/** @description : 상담사 클릭 이벤트  */
		onRowClick(e) {
			let keys = e.component.getSelectedRowKeys();
			let index = keys.indexOf(e.key);

			if (index > -1) {
				keys.splice(index, 1);
			} else {
				keys.push(e.key);
			}

			e.component.selectRows(keys);
		},
		/** @description: 시험지 선택 메서드 */
		chooseExamSheet() {
			this.onOpenModal('ModalChooseSheet', {
				title: '시험지 선택',
				buttons: {
					save: { text: '선택' },
					cancel: { text: '닫기' },
				},
				width: '800',
				height: '600',
			});
		},
		/** @description : 채점자 삭제 */
		async onManagerDeleteData() {
			const selectedRowKeys = this.$refs.managerGridData.instance.getSelectedRowKeys();

			if (!selectedRowKeys?.length) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			const idNull = selectedRowKeys.filter(d => d.id == null);
			const idNotNull = selectedRowKeys.filter(d => d.id != null);

			if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			let data = idNotNull.map(item => item.id);
			let agtidData = idNull.map(item => item.agtid);

			const payload = {
				actionname: 'EWM_EXAM_SCHE_DETAIL_MANAGER_DELETE',
				data: {
					data: {
						managerIds: data,
					},
				},
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.managerGridData.dataSource = this.managerGridData.dataSource.filter(
					m => !agtidData.includes(m.agtid) && !data.includes(m.id),
				);
				this.modal.agent.managerSelectedIdList = this.managerGridData.dataSource.map(item => item.agtid);
				this.targetGridData.dataSource.forEach(item => {
					if (!this.modal.agent.managerSelectedIdList.includes(item.managerId)) {
						item.managerId = null;
						item.managerNm = null;
					}
				});
				this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 채점자 자동배정 */
		async onAssignManager() {
			if (!this.targetGridData.dataSource || !this.managerGridData.dataSource) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}
			if (!(await this.$_Confirm('기존 배정된 채점자도 자동배정 됩니다.\n자동배정 하시겠습니까?', { title: `<h2>알림</h2>` }))) {
				return;
			}
			this.managerGridData.dataSource.map(item => (item.targetCnt = 0));

			this.targetGridData.dataSource.forEach(t => {
				const randomId = this.managerGridData.dataSource[Math.floor(Math.random() * this.managerGridData.dataSource.length)];
				randomId.targetCnt = randomId.targetCnt === 0 ? 1 : randomId.targetCnt + 1;

				t.managerId = randomId.agtid;
				t.managerNm = randomId.agtNm;
			});
			this.$refs.managerGridData.instance.refresh();
			this.$refs.targetGridData.instance.refresh();
		},
		/** @description : 대상자 삭제 */
		async onTargetDeleteData() {
			const selectedRowKeys = this.$refs.targetGridData.instance.getSelectedRowKeys();

			if (!selectedRowKeys?.length) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			const idNull = selectedRowKeys.filter(d => d.id == null);
			const idNotNull = selectedRowKeys.filter(d => d.id != null);

			if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			let data = idNotNull.map(item => item.id);
			let agtidData = idNull.map(item => item.agtid);

			const payload = {
				actionname: 'EWM_EXAM_SCHE_DETAIL_TARGET_DELETE',
				data: {
					data: {
						targetIds: data,
					},
				},
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.targetGridData.dataSource = this.targetGridData.dataSource.filter(
					m => !agtidData.includes(m.agtid) && !data.includes(m.id),
				);
				this.modal.agent.targetSelectedIdList = this.targetGridData.dataSource.map(item => item.agtid);
				this.managerGridData.dataSource.forEach(item => {
					const matchingItems = this.targetGridData.dataSource.filter(target => target.managerId === item.agtid);
					item.targetCnt = matchingItems.length;
				});
				this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 채점자 배정 편집 */
		onEditTargetManager(e, templateData) {
			this.targetGridData.selectedRowsData = templateData.data;

			this.onOpenModal(
				'ModalMarkerEdit',
				{
					title: '시험채점자 편집',
					buttons: {
						save: { text: '저장' },
						cancel: { text: '닫기' },
					},
					width: '30%',
					height: '60%',
				},
				this.managerGridData.dataSource,
			);
		},
		onOpenModal(componentNm, componentInitData, data) {
			this.modal.componentName = componentNm;
			this.modal.currentComponent = componentNm;
			this.modal.initData = componentInitData;
			this.modal.contentData = data;
			this.isOpenModal(true);
		},
		/** @description: 팝업 오픈 체크 메서드 */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
			}
		},
		onOpen(string) {
			if (string === 'MarkerAdd') {
				this.modal.agent.selectedIdList = this.modal.agent.managerSelectedIdList;
			} else if (string === 'TargetAdd') {
				this.modal.agent.selectedIdList = this.modal.agent.targetSelectedIdList;
			}
			this.modal.agent.locName = string;
			this.modal.agent.popupVisible = true;
		},
		onClose() {
			this.modal.agent.popupVisible = false;
			this.modal.agent.selectedIdList = [];
		},
		onSave(value) {
			if (!value.length) {
				return;
			}
			if (this.modal.agent.locName === 'MarkerAdd') {
				if (this.managerGridData.dataSource) {
					let currentManagers = this.managerGridData.dataSource; //현재 저장된 채점자
					currentManagers.forEach(m => {
						if (value.findIndex(v => v.agtid == m.agtid) < 0) {
							m.delFl = 'Y';
						} else {
							m.delFl = 'N';
						}
					});
					this.managerGridData.dataSource = currentManagers.filter(d => d.delFl != 'Y');
					let data = [];
					value.forEach(v => {
						if (this.managerGridData.dataSource.findIndex(m => v.agtid == m.agtid) < 0) {
							data.push({
								id: null,
								scheId: null,
								deptNmPath: v.deptNmPath,
								agtid: v.agtid,
								agtNm: v.agtNm,
								targetCnt: 0,
							});
						}
					});
					this.managerGridData.dataSource = [...this.managerGridData.dataSource, ...data];
				} else {
					this.managerGridData.dataSource = value;
				}
				this.modal.agent.managerSelectedIdList = value.map(item => item.agtid);
			} else if (this.modal.agent.locName === 'TargetAdd') {
				if (this.targetGridData.dataSource) {
					let currentTargets = this.targetGridData.dataSource; //현재 저장된 대상자
					currentTargets.forEach(t => {
						if (value.findIndex(v => v.agtid == t.agtid) < 0) {
							t.delFl = 'Y';
						} else {
							t.delFl = 'N';
						}
					});
					this.targetGridData.dataSource = currentTargets.filter(d => d.delFl != 'Y');
					let data = [];
					value.forEach(v => {
						if (this.targetGridData.dataSource.findIndex(t => v.agtid == t.agtid) < 0) {
							data.push({
								id: null,
								scheId: null,
								deptNmPath: v.deptNmPath,
								workgroupNm: v.workgroupNm,
								agtid: v.agtid,
								agtNm: v.agtNm,
								managerId: null,
								managerNm: null,
							});
						}
					});
					this.targetGridData.dataSource = [...this.targetGridData.dataSource, ...data];
				} else {
					this.targetGridData.dataSource = value;
				}
				this.modal.agent.targetSelectedIdList = value.map(item => item.agtid);
			}
			this.onClose();
		},
		//** @description: 시험지 미리보기 메서드 */
		previewExamSheet() {
			console.log('this.sheetData', this.sheetData);

			if (!this.sheetData.id) {
				this.$_Msg('시험지를 먼저 선택하세요.');
				return;
			}

			if (!this.config.updateYn) {
				//시험지 추가
				let params = {
					data: this.sheetData,
					configData: this.examScheduleInfo,
					updateYn: false,
					loc: 'examSche',
				};
				this.$store.commit('setDetailParams', params);
				this.$router.push({ path: '/ewm/exam/setting/sheet/preview' });
			} else if (this.config.updateYn) {
				//시험지 수정
				let params = {
					data: this.sheetData,
					configData: this.examScheduleInfo,
					updateYn: true,
					loc: 'examSche',
				};
				this.$store.commit('setDetailParams', params);
				this.$router.push({ path: '/ewm/exam/setting/sheet/preview' });
			} else {
				this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
			}
		},
		async onConfirmModal() {
			console.log(this.modal.contentData);
			let objectArray = this.modal.contentData;

			if (this.modal.componentName === 'ModalChooseSheet') {
				//시험지 선택
				console.log('시험지 선택');
				if (objectArray === undefined || objectArray.val.length === 0) {
					this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
					return false;
				}

				this.sheetData = objectArray.val[0];
				this.sheetData.examTypeNm = this.$_enums.ewm.examSheetType.values.find(c => c.examSheetType === this.sheetData.value).label;
				// this.sheetData.examNm = this.sheetData.examNm;
			} else if (this.modal.componentName === 'ModalMarkerEdit') {
				//채점자 편집
				console.log('채점자 편집');

				if (objectArray == null) {
					this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
					return false;
				}

				this.managerGridData.dataSource.forEach(d => {
					if (d.agtid === this.targetGridData.selectedRowsData.managerId) {
						d.targetCnt = d.targetCnt - 1;
					}
				});
				this.targetGridData.selectedRowsData.managerNm = objectArray[0].agtNm;
				this.targetGridData.selectedRowsData.managerId = objectArray[0].agtid;
				this.managerGridData.dataSource.forEach(d => {
					if (d.agtid === objectArray[0].agtid) {
						d.targetCnt = d.targetCnt === undefined ? 1 : d.targetCnt + 1;
					}
				});
			}
			this.isOpenModal(false);
		},
		/** @description: 저장 버튼 클릭 이벤트 */
		async onSaveFormData() {
			if (
				!this.examScheduleInfo.scheNm ||
				!this.sheetData.id ||
				!this.examScheduleInfo.examStartDt ||
				!this.examScheduleInfo.examStartTime ||
				!this.examScheduleInfo.examEndTime ||
				!this.examScheduleInfo.examFeedbackStartDt ||
				!this.examScheduleInfo.examFeedbackEndDt
			) {
				return;
			}

			const pad = num => num.toString().padStart(2, '0');

			const startDate = new Date(this.examScheduleInfo.examStartDt);
			const year = startDate.getFullYear();
			const month = pad(startDate.getMonth() + 1);
			const scheYmd = `${year}${month}`;

			const startTime = new Date(this.examScheduleInfo.examStartTime);
			const examStartHours = startTime.getHours();
			const examStartMin = startTime.getMinutes();
			const examStartTime = `${pad(examStartHours)}${pad(examStartMin)}00`;

			const endTime = new Date(this.examScheduleInfo.examEndTime);
			const examEndtHours = endTime.getHours();
			const examEndtMin = endTime.getMinutes();
			const examEndTime = `${pad(examEndtHours)}${pad(examEndtMin)}00`;

			let managers = [];
			if (!this.managerGridData.dataSource || this.managerGridData.dataSource.length < 1) {
				this.$_Msg('채점자를 선택해주세요.');
				return;
			}
			this.managerGridData.dataSource.forEach(m => {
				let row = {
					id: m.id,
					managerId: m.agtid,
				};
				managers.push(row);
			});

			let targets = [];
			if (!this.targetGridData.dataSource || this.targetGridData.dataSource.length < 1) {
				this.$_Msg('대상자를 선택해주세요.');
				return;
			}

			const managerNull = this.targetGridData.dataSource.filter(m => !m.managerId || m.managerId === null);
			console.log('????????????????', managerNull);
			if (managerNull.length > 0) {
				this.$_Msg('채점자를 배정해주세요.');
				return;
			}

			this.targetGridData.dataSource.forEach(t => {
				let row = {
					id: t.id,
					managerId: t.managerId,
					targetId: t.agtid,
				};
				targets.push(row);
			});

			let params = {
				scheId: this.examScheduleInfo.scheId,
				scheNm: this.examScheduleInfo.scheNm,
				scheYmd: scheYmd,
				examQuestionSheetId: this.sheetData.id,
				examQuestionRandCd: this.examScheduleInfo.examQuestionRandCd === false ? 0 : 1,
				examQuestionRandFl: this.examScheduleInfo.examQuestionRandCd === false ? 'N' : 'Y',
				examStartDt: this.examScheduleInfo.examStartDt,
				examEndDt: this.examScheduleInfo.examStartDt,
				examStartTime: examStartTime,
				examEndTime: examEndTime,
				examFeedbackStartDt: this.examScheduleInfo.examFeedbackStartDt,
				examFeedbackEndDt: this.examScheduleInfo.examFeedbackEndDt,
				managers: managers,
				targets: targets,
			};
			console.log(params);

			const payload = {
				actionname: 'EWM_EXAM_SCHE_INSERT',
				data: params,
				loading: false,
				useErrorPopup: true,
			};
			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				this.onCancelFormData();
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description: 취소 버튼 클릭 이벤트 */
		onCancelFormData() {
			this.$router.push({ path: '/ewm/exam/implementation/exam-schedule/list' });
		},
		/** @description: 데이터 조회 메서드 */
		async selectFormData() {
			if (this.config.updateYn) {
				//수정페이지
				const payload = {
					actionname: 'EWM_EXAM_SCHE_DETAIL_SELECT',
					data: {
						scheId: this.examScheduleInfo.scheId,
					},
					loading: false,
					useErrorPopup: true,
				};

				const res = await this.CALL_EWM_API(payload);

				if (res.status === 200) {
					if (res.data.header.resCode === 'success') {
						let data = res.data.data[0];

						this.examScheduleInfo = data;
						this.examScheduleInfo.examQuestionRandCd = this.examScheduleInfo.examQuestionRandCd === 0 ? false : true;
						let theDay = new Date(this.examScheduleInfo.examStartDt);
						const year = theDay.getFullYear().toString();
						const month = theDay.getMonth().toString();
						const day = theDay.getDate().toString();
						this.examScheduleInfo.examStartTime = new Date(
							year,
							month,
							day,
							this.examScheduleInfo.examStartTime.substring(0, 2),
							this.examScheduleInfo.examStartTime.substring(2, 4),
						);
						this.examScheduleInfo.examEndTime = new Date(
							year,
							month,
							day,
							this.examScheduleInfo.examEndTime.substring(0, 2),
							this.examScheduleInfo.examEndTime.substring(2, 4),
						);
						// console.log(this.examScheduleInfo)

						this.managerGridData.dataSource = data.managers; //채점자
						this.managerGridData.dataSource.forEach(m => {
							//m.id = m.id;
							//m.scheId = m.scheId;
							m.deptNmPath = m.managerPathNmFull.replace(/‡/g, '>');
							m.agtid = m.managerId;
							m.agtNm = m.managerAgtNm;
							//m.targetCnt = m.targetCnt;
							this.modal.agent.managerSelectedIdList.push(m.managerId);
						});

						this.targetGridData.dataSource = data.targets; //시험대상자
						this.targetGridData.dataSource.forEach(t => {
							//t.id = t.id;
							//t.scheId = t.scheId;
							t.deptNmPath = t.targetPathNmFull.replace(/‡/g, '>');
							t.workgroupNm = t.codeNm;
							t.agtid = t.targetId;
							t.agtNm = t.targetAgtNm;
							//t.managerId = t.managerId;
							t.managerNm = t.managerAgtNm;
							this.modal.agent.targetSelectedIdList.push(t.targetId);
						});
						// this.formData.examNm = this.initData.examNm;

						// if (this.examScheduleInfo.examProcess || this.examScheduleInfo.examProcessCd) {
						// 	this.config.canModify =
						// 		this.examScheduleInfo.examProcess === this.$_enums.ewm.examProcess.PROGRESS_READY.value ||
						// 		this.examScheduleInfo.examProcessCd == 1028;
						// }
						if (this.examScheduleInfo.examProcess) {
							this.config.canModify = this.examScheduleInfo.examProcess === this.$_enums.ewm.examProcess.PROGRESS_READY.value;
						}
					}
				}
			}
			// else{ //추가페이지
			//     this.examScheduleInfo = {};
			// }
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_edu_exam_type,root_ewm_hr_workgroup');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		async createdData() {
			vm = this;

			this.reqParams = this.$store.getters.getDetailParams;
			if (!this.reqParams) {
				this.$_goPrePage();
				return;
			}

			this.initCodeMap().then(() => {});

			console.log('this.reqParams', this.reqParams);

			this.config.updateYn = this.reqParams ? this.reqParams.updateYn : false;
			if (this.config.updateYn) {
				if (this.reqParams.data) {
					this.examScheduleInfo = this.reqParams.data;
					this.config.canModify = this.reqParams.data.examProcess === this.$_enums.ewm.examProcess.PROGRESS_READY.value; //대기
					this.sheetData.id = this.examScheduleInfo.sheetId;
					this.sheetData.examNm = this.examScheduleInfo.examNm;
					this.sheetData.examTypeNm = this.$_enums.ewm.examSheetType.values.find(
						c => c.value === this.examScheduleInfo.examSheetType,
					).label;
				}

				if (this.reqParams.sheetData) {
					this.reqParams.sheetData.examTypeNm = this.reqParams.sheetData.examSheetType
						? this.$_enums.ewm.examSheetType.values.find(c => c.value === this.reqParams.sheetData.examSheetType).label
						: this.reqParams.sheetData.examTypeNm;
					this.sheetData = this.reqParams.sheetData;
				}
				console.log(this.sheetData.examTypeNm);
			} else {
				if (this.reqParams.sheetData) {
					this.examScheduleInfo = this.reqParams.data;
					this.reqParams.sheetData.examTypeNm = this.$_enums.ewm.examSheetType.values.find(
						c => c.value === this.reqParams.sheetData.examSheetType,
					).label;
					this.sheetData = this.reqParams.sheetData;
				}
			}
		},
		/** @description: 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.selectFormData();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>
